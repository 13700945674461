body, html {
	padding: 0;
	margin: 0;
}

body {
	font-family: 'Open Sans', sans-serif;
	color: #2d3138;
	background: #fff;
}
h1 {
	font-family: 'Asap', sans-serif;
	font-weight: 400;
}
a, a:hover, a:focus, a:active, a:visited {
	color: #fff;
	text-decoration: none;
}

.vcenter {
	display: flex;
    justify-content: center;
    align-items: center;
}
div.hero {
	height: calc(100vh - 5em);
	text-align: center;
}
footer {
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	background: #212428;
	color: #999;
	text-align: center;

	p {
		margin: 1em;
	}

	@media only screen and (max-width: 800px) {
		font-size: 10px;
	}
}
